import {RouteObject} from 'react-router-dom';
import React from 'react';
import {authGuard, AuthRoute} from '@common/auth/guards/auth-route';
import {lazyHcRoute} from '@hc/routes/lazy-hc-route';
import {hcRoutes} from '@hc/routes/hc-routes';

const TicketRoutes = React.lazy(
  () => import('@app/hc/tickets/hc-ticket-routes'),
);

export const appHcRoutes: RouteObject[] = [
  {
    path: 'hc',
    element: <AuthRoute requireLogin={false} permission="articles.view" />,
    children: [
      ...hcRoutes,
      {
        path: 'tickets',
        loader: () => authGuard(),
        children: [
          {
            index: true,
            lazy: () => lazyHcRoute('CustomerTicketListPage'),
          },
          {
            path: 'new',
            lazy: () => lazyHcRoute('CustomerNewTicketPage'),
          },
          {
            path: ':ticketId',
            lazy: () => lazyHcRoute('CustomerTicketPage'),
          },
        ],
      },
    ],
  },
];

import {createBrowserRouter} from 'react-router-dom';
import {authGuard, AuthRoute} from '@common/auth/guards/auth-route';
import React from 'react';
import {authRoutes} from '@common/auth/auth-routes';
import {notificationRoutes} from '@common/notifications/notification-routes';
import {DynamicHomepage} from '@common/ui/other/dynamic-homepage';
import {HcLandingPage} from '@hc/homepage/hc-landing-page';
import {RootRoute} from '@common/core/common-provider';
import {GuestRoute} from '@common/auth/guards/guest-route';
import {RegisterPage} from '@common/auth/ui/register-page';
import {getBootstrapData} from '@ui/bootstrap-data/bootstrap-data-store';
import {adminRoutes} from '@common/admin/routes/admin-routes';
import {agentRoutes} from '@app/agent/routes/agent-routes';
import {appHcRoutes} from '@app/hc/routes/app-hc-routes';
import {commonRoutes} from '@common/core/common-routes';

export const appRouter = createBrowserRouter(
  [
    {
      id: 'root',
      element: <RootRoute />,
      children: [
        {
          path: '/',
          element: (
            <DynamicHomepage
              homepageResolver={() => (
                <AuthRoute requireLogin={false} permission="articles.view">
                  <HcLandingPage />
                </AuthRoute>
              )}
            />
          ),
        },
        ...authRoutes,
        ...notificationRoutes,
        ...appHcRoutes,
        ...agentRoutes,
        ...adminRoutes,
        ...commonRoutes,
        {
          path: '/join/agents/:inviteId',
          element: (
            <GuestRoute>
              <RegisterPage inviteType="agentInvite" />
            </GuestRoute>
          ),
        },
        {
          path: 'api-docs',
          loader: () =>
            authGuard({permission: 'api.access', requireLogin: false}),
          lazy: () => import('@common/swagger/swagger-api-docs-page'),
        },
      ],
    },
  ],
  {
    basename: getBootstrapData().settings.html_base_uri,
  },
);
